import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import classNames from "classnames";

import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import PieChartWidget from "../../_common/pieChartWidget/PieChartWidget";

import s from "./SentimentPieChart.module.less";

import { minScreen1840 } from "@utils/screens";
import { IFilters } from "@store/filters/types";
import { IPieChartUnit } from "../../search/Search.type";
import { normalizePlatformData } from "../../search/helpers";
import { IExplorerOverviewSentiments } from "@store/dashboard/types";

interface ISentimentPieChartProps extends HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  filterData: IFilters;
  data: IExplorerOverviewSentiments[];
}

const SentimentPieChart: FC<ISentimentPieChartProps> = ({
  loading,
  filterData,
  data,
  className,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [commentSentimentData, setCommentSentimentData] = useState<IPieChartUnit[]>([]);
  const [commentSentimentTotal, setTotal] = useState<IPieChartUnit>({} as IPieChartUnit);

  useEffect(() => {
    const initializeSearchData = () => {
      const normalizedSentimentData = normalizePlatformData(data);

      const pieChartTotal = normalizedSentimentData.find(
        (unit: IPieChartUnit) => unit.rawName === "total",
      ) as IPieChartUnit;

      const pieChartData = normalizedSentimentData
        .filter(unit => unit.rawName !== "total")
        .map(unit => {
          if (unit.rawName === "negative" && unit.change_percentage) {
            return { ...unit, type: "negative" };
          }
          return unit;
        }) as IPieChartUnit[];

      setTotal(pieChartTotal);
      setCommentSentimentData(pieChartData);
    };

    initializeSearchData();
  }, [data]);

  const onChartClick = (event: { payload: { rawName: string } }) => {
    const filters = {
      ...filterData,
      sentiments: [event.payload.rawName],
    };

    navigate("/search", {
      state: { filters },
    });
  };

  return (
    <Card
      headerTitle={t("components:sentimentPieChart:headerTitle")}
      headerSubTitle={t("components:sentimentPieChart:headerSubTitle")}
      className={classNames(s.bbSentimentPieChartWrapper, className)}
    >
      <div className={s.bbSentimentPieChart}>
        <PieChartWidget
          loading={loading}
          position="center"
          radiuses={[minScreen1840 ? 65 : 50, minScreen1840 ? 90 : 70]}
          sizes={[minScreen1840 ? 200 : 140, minScreen1840 ? 200 : 140]}
          onChartClick={onChartClick}
          defaultValue={commentSentimentTotal}
          chartData={commentSentimentData.filter(unit => unit.value !== 0)}
        />
      </div>
    </Card>
  );
};

export default SentimentPieChart;
