import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState, forwardRef } from "react";

import { Grid } from "antd";
import Filters from "./Filters";
import FilterLabel from "./filterLabel/FilterLabel";
import { SelectOld, Button } from "@bbdevcrew/bb_ui_kit_fe";
import { SavedFilter } from "./filters/SavedFilters/SavedFilter";

import {
  predefinedFiltersSelector,
  getPredefinedFiltersFailedSelector,
  getPredefinedFiltersPendingSelector,
  getPredefinedFiltersSuccessfulSelector,
} from "@store/savedFilters/selectors";
import { meSelector } from "@store/me/selectors";
import { getPredefinedFiltersAction } from "@store/savedFilters/actions";
import { saveFilterAction, toggleOpenFiltersPanelAction } from "@store/filters/actions";
import { filtersOpenSelector, filtersPanelWidthSelector } from "@store/filters/selectors";

import s from "./AppFilters.module.less";

import { IFilters } from "@store/filters/types";
import { IFilterItem, ISavedFilter, IAppFiltersProps } from "./AppFilters.type";
import { getDefaultFilterValue, mapFilterItemToSelectProps } from "@utils/filters";
import { IFormHandler } from "./Filters.type";

import { ChevronRightIcon, ChevronLeftIcon } from "@bbdevcrew/bb_ui_kit_fe";

const AppFilters = forwardRef<IFormHandler, IAppFiltersProps>(
  (
    {
      clientData,
      hasNewSidenav,
      customFilters,
      hideSavedFilters,
      initialFilterData,
      hideFilterButtons,
      stickyFilterValue,
      showModerationGroups,
      className,
      onFilter,
      onResetFilters,
      onSavedFilterChange,
      onSavedStickyChange,
      setStickyFilterValue,
    },
    filtersRef,
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const screens = Grid.useBreakpoint();
    const isMobileView = !screens.md;

    const me = useSelector(meSelector);
    const filtersPanelOpen = useSelector(filtersOpenSelector);
    const filtersPanelWidth = useSelector(filtersPanelWidthSelector);
    const predefinedFilters = useSelector(predefinedFiltersSelector);
    const predefinedFiltersFetched = useSelector(getPredefinedFiltersSuccessfulSelector);
    const fetchingPredefinedFilters = useSelector(getPredefinedFiltersPendingSelector);
    const predefinedFiltersFetchFailed = useSelector(getPredefinedFiltersFailedSelector);

    const filtersFormSizes = {
      width: isMobileView ? "100%" : filtersPanelWidth,
      height: !filtersPanelOpen ? "100%" : undefined,
    };

    const [moderationGroupsValue, setModerationGroupsValue] = useState<string | null>();
    const [moderationGroupOptions, setModerationGroupOptions] = useState<IFilterItem[]>([]);

    const toggleFiltersPanel = useCallback(
      (open: boolean) => dispatch(toggleOpenFiltersPanelAction(open)),
      [dispatch],
    );

    const getPredefinedFilters = useCallback(
      () => dispatch(getPredefinedFiltersAction()),
      [dispatch],
    );

    const setSavedFilterValue = useCallback(
      (id: string | number | null) => dispatch(saveFilterAction(id)),
      [dispatch],
    );

    useEffect(() => {
      if (
        !predefinedFilters.length &&
        !predefinedFiltersFetched &&
        !fetchingPredefinedFilters &&
        !predefinedFiltersFetchFailed
      )
        getPredefinedFilters();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (showModerationGroups) {
        const moderationGroups = predefinedFilters.find(({ id }) => id === "moderationGroups");

        setModerationGroupOptions(mapFilterItemToSelectProps(moderationGroups?.items || []));
      }
      // eslint-disable-next-line
    }, [predefinedFilters]);

    const getDefaultFilters = useCallback(
      () => getDefaultFilterValue(me),
      // eslint-disable-next-line
      [clientData],
    );

    useEffect(() => {
      if (initialFilterData.saved_filter && predefinedFilters.length) {
        // It's a sticky filter -> id = 'dsf766-sad324-asd3'
        if (Number.isNaN(Number(initialFilterData.saved_filter))) {
          setStickyFilterValue(initialFilterData.saved_filter);
        } else {
          setSavedFilterValue(Number(initialFilterData.saved_filter));
        }

        onSavedStickyChange(initialFilterData.saved_filter);
      }
      // eslint-disable-next-line
    }, [initialFilterData, predefinedFilters]);

    const onModerationGroupChange = (value: string) => {
      setModerationGroupsValue(value);
      const groups = predefinedFilters.find(({ id }) => id === "moderationGroups");
      const selectedGroup = groups?.items.find(({ id }) => id === value) || null;
      const requestData = selectedGroup ? selectedGroup.request : getDefaultFilters();

      onFilter(requestData, {
        saved_filter: value,
      });
    };

    const onInternalFilter = (
      filterRequest: IFilters,
      param?: string | ISavedFilter | undefined,
    ) => {
      setSavedFilterValue(null);
      setStickyFilterValue(null);
      setModerationGroupsValue(null);
      onFilter(filterRequest, param);
    };

    const renderFilterButtons = () => {
      return (
        <div
          style={{ width: isMobileView ? "100%" : filtersPanelWidth }}
          className={classNames(
            s.bbAppFilterFooterButtons,
            {
              [s.bbAppFilterFooterButtonsMobile]: isMobileView,
            },
            className,
          )}
        >
          <Button
            _size="sm"
            _type="secondary"
            type="submit"
            id="reset-filters"
            onClick={onResetFilters}
            className={s.bbFiltersResetBtn}
            data-cy="reset-filters-button"
            data-stonly-target="app-global__filters--clear-all-button"
          >
            {t("generic:clearAll")}
          </Button>
          <Button
            _size="sm"
            _type="primary"
            type="submit"
            id="apply-filter"
            className={s.bbFiltersApplyBtn}
            data-cy="apply-filters-button"
            data-stonly-target="app-global__filters--apply-button"
          >
            {t("components:filters:applyBtn")}
          </Button>
        </div>
      );
    };

    return (
      <div
        className={classNames(s.bbAppFiltersWrapper, {
          [s.bbAppFiltersWrapperDesktop]: !isMobileView,
          [s.bbAppFiltersWrapperDesktopCollapsed]: !isMobileView && !filtersPanelOpen,
          [s.bbAppFiltersWrapperMobile]: isMobileView,
        })}
      >
        <div
          className={classNames(s.bbAppFiltersForm, {
            [s.bbAppFiltersFormNoMenu]: hasNewSidenav,
          })}
          style={filtersFormSizes}
          data-cy="app-filters-form"
        >
          {!isMobileView && (
            <Button
              className={s.bbToggleFiltersPanelBtn}
              style={{ right: filtersPanelWidth - 7 }}
              onClick={() => toggleFiltersPanel(!filtersPanelOpen)}
            >
              {filtersPanelOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </Button>
          )}
          {(filtersPanelOpen || isMobileView) && (
            <Filters
              ref={filtersRef}
              onFilter={onInternalFilter}
              customFilters={customFilters}
              initialFilterData={initialFilterData}
              filterPlacement={!screens.md ? "left" : "right"}
              clientPlatformTypes={clientData?.platform_types || []}
              className={s.bbAppFiltersFormWrapper}
            >
              {{
                prefix: (
                  <div className={s.bbSavedFilterWrapper}>
                    <div className={s.bbSavedFilterTitle}>{t("generic:filters")}</div>
                    {hideSavedFilters ? null : (
                      <SavedFilter
                        initialFilterData={initialFilterData}
                        onSavedChange={onSavedFilterChange}
                      />
                    )}
                  </div>
                ),
                assetsInfix: (
                  <React.Fragment key={stickyFilterValue}>
                    {showModerationGroups ? (
                      <div className={s.bbModerationGroups}>
                        <FilterLabel label={t("components:filters:moderationGroups:label")} />
                        <SelectOld
                          noBorder
                          allowClear
                          size="middle"
                          id="mod-grp-select"
                          value={moderationGroupsValue}
                          dropdownMatchSelectWidth={false}
                          onChange={onModerationGroupChange}
                          options={moderationGroupOptions || []}
                          data-cy="moderation-groups-filter-select"
                          placeholder={t("components:filters:moderationGroups:placeholder")}
                          onClear={() => {
                            setModerationGroupsValue(null);
                            onFilter(getDefaultFilters());
                          }}
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                ),
                suffix: <>{!hideFilterButtons ? renderFilterButtons() : null}</>,
              }}
            </Filters>
          )}
        </div>
      </div>
    );
  },
);

AppFilters.displayName = "AppFilters";

export default AppFilters;
