import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";

import ColorDot from "../ColorDot";
import ClientPlanBadge from "./ClientPlanBadge";
import { Tooltip, SimpleSelectTrigger } from "@bbdevcrew/bb_ui_kit_fe";
import SavedFilterSelector from "../AppFilter/filters/SavedFilters/SavedFilterSelect";

import { meSelector } from "@store/me/selectors";
import { savedFilterValueSelector } from "@store/filters/selectors";
import { getModuleSectionsListAction } from "@store/modules/actions";
import { getAllModulesSectionsSelector } from "@store/modules/selectors";

import s from "./AppSidebar.module.less";

import { cropText } from "@utils/cropText";
import { getSubFeatureName } from "@utils/feature";
import { IAppSidebarProps } from "./AppSidebar.types";
import { IAvailableSectionsType } from "@store/modules/types";
import { SubFeatureModuleNameMap, ModuleIconMap } from "./helpers";
import { useSavedFiltersList } from "@utils/useSavedFiltersList";

import { ChevronLeftIcon, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

enum SidebarPanelStateEnum {
  EXPANDED,
  COLLAPSED,
  COLLAPSE_PREVIEW,
}

const AppSidebar: FC<IAppSidebarProps> = ({ hasSavedFilters, onSavedFilterChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const subFeatureName = getSubFeatureName();
  const savedFiltersList = useSavedFiltersList();
  const [searchParams, setSearchParams] = useSearchParams();

  const allConversationsText = t(
    "pages:dashboard:modals:savesSearch:filterSelector:allConversations",
  );

  const moduleId = SubFeatureModuleNameMap[subFeatureName as keyof typeof SubFeatureModuleNameMap];
  const [sidebarPanelState, setSidebarPanelState] = useState<SidebarPanelStateEnum>(
    SidebarPanelStateEnum.EXPANDED,
  );

  const me = useSelector(meSelector);
  const savedFilterValue = useSelector(savedFilterValueSelector);
  const modulesSections = useSelector(getAllModulesSectionsSelector);

  const sections = modulesSections[moduleId as IAvailableSectionsType];

  const isCollapsed = sidebarPanelState === SidebarPanelStateEnum.COLLAPSED;
  const isExpanded = sidebarPanelState === SidebarPanelStateEnum.EXPANDED;
  const isPreviewMode = sidebarPanelState === SidebarPanelStateEnum.COLLAPSE_PREVIEW;

  const collapsePanel = () => setSidebarPanelState(SidebarPanelStateEnum.COLLAPSED);
  const expandPanel = () => setSidebarPanelState(SidebarPanelStateEnum.EXPANDED);
  const previewPanel = () => setSidebarPanelState(SidebarPanelStateEnum.COLLAPSE_PREVIEW);

  const getModuleSectionsList = useCallback(
    (module_id: string) => dispatch(getModuleSectionsListAction(module_id)),
    [dispatch],
  );

  const setActiveSection = useCallback(
    (id: string) => {
      const newParams = new URLSearchParams(new URL(document.location.href).searchParams);
      newParams.set("section", id);
      setSearchParams(newParams);
    },
    [setSearchParams],
  );

  useEffect(() => {
    getModuleSectionsList(moduleId);
  }, [moduleId, getModuleSectionsList]);

  useEffect(() => {
    const sectionId = searchParams.get("section");

    if (!sectionId) {
      const DEFAULT_SECTION = sections
        ?.flatMap(section => section.features)
        .find(feature => feature.selected)?.id;

      if (DEFAULT_SECTION) setActiveSection(DEFAULT_SECTION);
    }
  }, [searchParams, setActiveSection, sections]);

  const savedFilterItem = useMemo(() => {
    return savedFiltersList.find(filter => filter.id.toString() === savedFilterValue?.toString());
  }, [savedFiltersList, savedFilterValue]);

  return (
    <div
      className={classNames(s.bbAppSidebarWrapper, {
        [s.bbAppSidebarCollapsedWrapper]: isCollapsed || isPreviewMode,
      })}
    >
      <div
        className={classNames(s.bbAppSidebar, {
          [s.bbAppSidebarFullWidth]: isPreviewMode || isExpanded,
        })}
        onMouseLeave={isPreviewMode ? collapsePanel : undefined}
      >
        <div
          className={s.bbAppSidebarSections}
          onMouseEnter={isCollapsed ? previewPanel : undefined}
        >
          {sections?.map((section, sectionIndex) => (
            <Fragment key={section.id}>
              <div key={section.id} className={s.bbAppSidebarSection}>
                <div className={s.bbAppSidebarSectionLabel}>
                  {!isCollapsed ? section.label : ""}
                </div>

                {hasSavedFilters && sectionIndex === 0 && (
                  <SavedFilterSelector
                    value={String(savedFilterValue)}
                    onChange={newValue => onSavedFilterChange(newValue)}
                  >
                    {({ open }) => (
                      <SimpleSelectTrigger
                        active={open}
                        className={classNames(s.bbSavedFiltersSelect, {
                          [s.bbSavedFiltersSelectCollapsed]: isCollapsed,
                        })}
                      >
                        {savedFilterValue === null ? (
                          <div className={s.bbSavedFiltersSelectValue}>
                            <ColorDot color="all" />
                            {!isCollapsed && allConversationsText}
                          </div>
                        ) : (
                          <div className={s.bbSavedFiltersSelectValue}>
                            {savedFilterItem?.color ? (
                              <ColorDot color={savedFilterItem?.color} />
                            ) : null}
                            {!isCollapsed && cropText(savedFilterItem?.name || "", 15)}
                          </div>
                        )}
                      </SimpleSelectTrigger>
                    )}
                  </SavedFilterSelector>
                )}

                {section.features.map(feature => (
                  <div
                    key={feature.id}
                    onClick={() => setActiveSection(feature.id)}
                    className={classNames(s.bbAppSidebarFeature, {
                      [s.bbAppSidebarFeatureActive]: feature.id === searchParams.get("section"),
                    })}
                  >
                    {ModuleIconMap[moduleId][feature.id]}
                    <span className={s.bbAppSidebarFeatureLabel}>
                      {!isCollapsed ? feature.label : ""}
                    </span>
                  </div>
                ))}
              </div>
              {sectionIndex !== sections.length - 1 && (
                <div className={s.bbAppSidebarSectionDivider} />
              )}
            </Fragment>
          ))}
        </div>
        <div className={s.bbClientPlanBadgeWrapper}>
          {!!me?.client?.client_plan && (
            <div onMouseEnter={isCollapsed ? previewPanel : undefined}>
              <ClientPlanBadge type={me.client.client_plan} showTitle={!isCollapsed} />
            </div>
          )}
          {!isCollapsed && (
            <Tooltip
              title={t(
                `components:listen:sidePanel:${
                  isPreviewMode ? "expandTooltip" : "collapsibleTooltip"
                }`,
              )}
            >
              <div
                className={s.bbSidebarCollapseIcon}
                onClick={isPreviewMode ? expandPanel : collapsePanel}
              >
                {isPreviewMode ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppSidebar;
