import { useSelector } from "react-redux";
import React, { FC, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import NotFoundView from "@containers/NotFoundView/NotFoundView";
import AppLayout from "@components/_common/AppLayout/AppLayout";
import PDFReport from "@components/managed/Transparency/PDFReport";

import { listenAppFeature, meSelector } from "@store/me/selectors";

import { IMenuItem } from "@components/_common/AppHeader/AppHeader.type";
import { IRestrictedRoutesProps } from "./Routes.type";
import { ListenSubFeatureComponents } from "./helpers";
import { useRoutes } from "@utils/useRoutes";

const ListenRoutes: FC<IRestrictedRoutesProps> = ({ menu, path }) => {
  const { extraMenuItems, redirectCallback } = useRoutes(path);

  const me = useSelector(meSelector);
  const feature = useSelector(listenAppFeature);

  const subfeatures = useMemo(() => feature?.subfeatures || [], [feature]);

  return (
    <>
      <Routes>
        <Route path="pdf_export/overview" element={<PDFReport type="listen-overview" />} />
        <Route
          path="*"
          element={
            <AppLayout
              basename="listen"
              extra={extraMenuItems}
              avatarUrl={me?.client?.logo}
              defaultSelectedKey="listening"
              menu={menu as unknown as IMenuItem[]}
              selectedKey={(path && path.replace(/\//g, "")) || "listening"}
            >
              {{
                content: (
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Navigate
                          replace
                          to={{
                            pathname: subfeatures.length ? subfeatures[0].id : "listening",
                            search: window.location.search,
                          }}
                        />
                      }
                    />
                    {subfeatures.length
                      ? subfeatures?.map(({ id, is_disabled }) => {
                          const RouteComponent =
                            ListenSubFeatureComponents[
                              id as keyof typeof ListenSubFeatureComponents
                            ] || null;

                          return (
                            <Route
                              key={id}
                              path={id}
                              element={
                                <PrivateRoute isDisabled={is_disabled}>
                                  <RouteComponent />
                                </PrivateRoute>
                              }
                            />
                          );
                        })
                      : null}
                    <Route
                      path="unauthorized"
                      element={<NotFoundView redirectCallback={redirectCallback} type="403" />}
                    />
                    <Route
                      path="*"
                      element={<NotFoundView redirectCallback={redirectCallback} />}
                    />
                  </Routes>
                ),
              }}
            </AppLayout>
          }
        />
      </Routes>
    </>
  );
};

export default ListenRoutes;
