import React, { FC, MouseEvent, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames/bind";

import { Modal } from "@bbdevcrew/bb_ui_kit_fe";

import DMVideoAttachment from "./variants/DMVideoAttachment";
import DMImageAttachment from "./variants/DMImageAttachment";
import DMPostAttachment from "./variants/DMPostAttachment";
import AudioAttachment from "./variants/AudioAttachment";
import VideoAttachment from "./variants/VideoAttachment";
import ImageAttachment from "./variants/ImageAttachment";

import { openedAttachmentSelector } from "@store/attachment/selectors";
import { closeAttachment, openAttachment } from "@store/attachment/actions";

import { IAttachment, IPrivateAttachment, IModalAttachmentProps } from "./Attachment.type";

import s from "./Attachment.module.less";

const cx = cn.bind(s);

const Attachments: FC<IModalAttachmentProps> = ({
  isOwned,
  createdTime,
  attachments,
  audioClassName,
  isStoryAttachment,
  className = "",
  isPreviewMessage,
}) => {
  const dispatch = useDispatch();
  const openedAttachment = useSelector(openedAttachmentSelector);

  const onAttachmentClick = (
    event: MouseEvent<HTMLSpanElement>,
    attachment: IAttachment | IPrivateAttachment,
  ) => {
    event.preventDefault();
    dispatch(openAttachment(attachment));
  };

  const onCancel = () => {
    dispatch(closeAttachment());
  };

  const showAttachmentModal = useMemo(
    () => !!openedAttachment && !!attachments.find(({ url }) => url === openedAttachment.url),
    [attachments, openedAttachment],
  );

  return (
    <>
      {!!attachments?.length && (
        <div className={cx(s.bbAttachmentWrapper, className)}>
          <span
            className={cx(s.bbCommentsMessageAttachment, {
              [s.bbCommentsMessageAttachmentPreviewMessage]: isPreviewMessage,
            })}
          >
            {attachments.map((attachment: IAttachment | IPrivateAttachment, index) => {
              const storyMediaData = {
                ...attachment,
                url: (attachment as IAttachment)?.media?.image?.src || attachment.url,
              };

              if (!isStoryAttachment && attachment.type === "post" && !!attachment.url) {
                return <DMPostAttachment key={index} url={attachment.url} />;
              }

              if (isStoryAttachment && attachment.type === "video")
                return (
                  <DMVideoAttachment
                    key={index}
                    isOwned={isOwned}
                    attachment={attachment}
                    createdTime={createdTime}
                    onAttachmentClick={onAttachmentClick}
                    isPreviewMessage={isPreviewMessage}
                    className={cx({ [s.bbDMVideoAttachmentOwned]: isOwned })}
                  />
                );
              else if (isStoryAttachment && attachment.type === "image")
                return (
                  <DMImageAttachment
                    key={index}
                    isOwned={isOwned}
                    attachment={storyMediaData}
                    createdTime={createdTime}
                    onAttachmentClick={onAttachmentClick}
                    isPreviewMessage={isPreviewMessage}
                    className={cx({ [s.bbDMImageAttachmentOwned]: isOwned })}
                  />
                );
              else if (attachment.type === "audio")
                return (
                  <AudioAttachment
                    key={index}
                    url={attachment.url}
                    audioClassName={audioClassName}
                  />
                );
              else if (attachment.type === "video")
                return (
                  <VideoAttachment
                    key={index}
                    attachment={attachment}
                    onAttachmentClick={onAttachmentClick}
                  />
                );
              else if (attachment.type === "image")
                return (
                  <ImageAttachment
                    key={index}
                    attachment={attachment}
                    onAttachmentClick={onAttachmentClick}
                  />
                );
            })}
          </span>
        </div>
      )}

      <Modal
        centered
        responsive
        hideFooter
        hideHeader
        open={showAttachmentModal}
        onCancel={onCancel}
      >
        {openedAttachment?.type === "image" && (
          <div className={s.bbAttachmentModalContent}>
            <img src={openedAttachment?.url} alt="Image preview" />
          </div>
        )}
        {openedAttachment?.type === "video" && (
          <div className={s.bbAttachmentModalContent}>
            <video controls width="400px" preload="metadata" style={{ borderRadius: "10px" }}>
              <source src={openedAttachment.url} type="video/mp4" />
            </video>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Attachments;
